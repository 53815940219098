import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				GYIK | Gyakran ismételt kérdések a The Gents' Barber Guildnél
			</title>
			<meta name={"description"} content={"A The Gents' Barber Guildnél megértjük, hogy a kiváló ápolás kérdésekkel jár. Itt találja a válaszokat a leggyakoribb kérdésekre, amelyeket kapunk, hogy a következő látogatásakor teljes körű tájékoztatást kapjon."} />
			<meta property={"og:title"} content={"GYIK | Gyakran ismételt kérdések a The Gents' Barber Guildnél"} />
			<meta property={"og:description"} content={"A The Gents' Barber Guildnél megértjük, hogy a kiváló ápolás kérdésekkel jár. Itt találja a válaszokat a leggyakoribb kérdésekre, amelyeket kapunk, hogy a következő látogatásakor teljes körű tájékoztatást kapjon."} />
			<meta property={"og:image"} content={"https://unitedmastenga.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://unitedmastenga.com/img/595777.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://unitedmastenga.com/img/595777.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://unitedmastenga.com/img/595777.png"} />
			<meta name={"msapplication-TileImage"} content={"https://unitedmastenga.com/img/595777.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-11">
			<Text
				margin="0px 0px 70px 0px"
				font="normal 900 52px/1.2 --fontFamily-sans"
				color="#0e1940"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
				sm-font="normal 900 36px/1.2 --fontFamily-sans"
			>
				Az Ön ápolási kérdései, megválaszolva
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							1. Kell-e időpontot foglalnom?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Bár szívesen látjuk az érdeklődőket, javasoljuk, hogy foglaljon időpontot a minimális várakozási idő és a személyre szabott élmény biztosítása érdekében.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							2. Mennyi az üzemideje?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Óráinkat úgy alakítottuk ki, hogy különböző időbeosztásokhoz igazodjanak. Kérjük, az aktuális nyitvatartási időnkről érdeklődjön közvetlenül nálunk.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							3. Kérhetek konkrét borbélyt?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Természetesen! Ha van kedvenc borbélya, tudassa velünk foglaláskor, és mi elintézzük.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							4. Mit tegyek, ha nem tudom biztosan, milyen frizurát szeretnék?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Fodrászaink gyakorlottan tudnak ajánlásokat tenni. Bátran beszélje meg velük életmódját és preferenciáit, hogy személyre szabott tanácsokat kaphasson.
						</Text>
					</Box>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 16px 0px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							5. Mennyi ideig tart egy átlagos hajvágás vagy borotválkozás?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Egy átlagos hajvágás vagy borotválkozás 30 perctől akár egy óráig is eltarthat, a szolgáltatás sajátosságaitól függően.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						border-radius="15px"
						padding="25px 25px 25px 25px"
						sm-padding="18px 18px 18px 18px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-sans" color="#0e1940">
							6. Kínálnak szakállvágást és ápolást?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet" color="#555a5f">
							Igen, átfogó szakállápolási szolgáltatásokat nyújtunk, beleértve a szakállvágást és -formázást is.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Bármilyen egyéb kérdéssel vagy a szolgáltatásainkkal kapcsolatos részletes információkkal kapcsolatban ne habozzon közvetlenül kapcsolatba lépni velünk. Célunk, hogy az Ön tapasztalata a The Gents' Barber Guildnél kivételes legyen.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://unitedmastenga.com/img/10.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});